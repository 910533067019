import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup

} from 'reactstrap';
import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from "../../components/forms/validation";

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import ListBuilder from '../../components/listBuilder';
import Isvg from 'react-inlinesvg';
import addIcon from '../../assets/svg/add-icon.svg';
import edit from '../../assets/svg/edit.svg';
import garabage from '../../assets/svg/garbage.svg'
import DeleteModal from '../../components/deleteModal';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

class OurSolutionPage extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                "query-group": null,
            },
            loading: true,
            items: [],
            total: 0,
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }
    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state,
            });
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: "sortField", value: field },
                        { name: "sortType", value: type },
                    ],
                    false
                )
            );
        }
    };

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            );
        }
    };
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            );
        }
    };

    stopAnimation = () => {
        this.player.current.play();
    }

    insertOrUpdate = (data) => {

        fetch(API_ENDPOINT + '/data/pages/our-solution/new', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error,
                })
            } else {
                this.setState({
                    form: null
                })
                this.get();
            }
        })


    }

    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/pages/our-solution/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        return (
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    <button className="add-button" onClick={() => this.setState({
                                        form: {}
                                    })}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                </div>

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true, multilang: true },

                                    ]}
                                    items={this.state.items.map(item => {
                                        return {
                                            ...item,
                                        }
                                    })}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={edit} className="edit-icon" />,
                                                onClick: (item) => {
                                                    this.props[0].history.push('/pages/edit-our-solution-page/' + item._id)
                                                }
                                            },
                                            {
                                                component: <Isvg src={garabage} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete page'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name[this.props.lang]}</strong>?
                                </DeleteModal>
                            </div>
                        </Col>
                    </Row>
                    {this.state.form ?
                        <FormBuilder dateFormat={this.props.dateFormat}
                            onSubmit={(data) => {
                                this.insertOrUpdate(data)
                            }}
                            initialValues={this.state.form}
                            size="lg"
                            isOpen={this.state.form}
                            toggle={() => this.setState({ form: null })}
                            title={this.state.form._id ? 'Edit page'.translate(this.props.lang) : 'Add new page'.translate(this.props.lang)}
                            _saving={this.state._saving}
                            buttonConfirmText={'Saved'.translate(this.props.lang)}
                            buttonText={'Save'.translate(this.props.lang)}

                            lang={this.props.lang}
                            beforeComponent={
                                <FormGroup >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ marginRight: 15 }}>
                                            <label>{'Language:'.translate(this.props.lang)}</label>
                                        </div>
                                        <div className="language" style={{ minWidth: 150 }}>
                                            <select
                                                value={this.props.lang}
                                                onChange={(e) => {
                                                    this.props.setLang(e.target.value);
                                                }}
                                            >
                                                <option value="se">Svenska</option>
                                                <option value="en">English</option>
                                            </select>
                                        </div>
                                    </div>
                                </FormGroup>

                            }
                            fields={[
                                {
                                    type: 'row',
                                    children: [

                                      
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'h4',
                                                    text:'Seo tag'
                                                },
                                                {
                                                    type: 'text',
                                                    name: 'seotag',
                                                    label: 'Title'.translate(this.props.lang),
                                                    multilang: true,
                                                    lang: this.props.lang,
                                                    // validate: [required('Title is required!'.translate(this.props.lang))]
                                                },
                                                {
                                                    type: 'html',
                                                    name: 'seoDescription',
                                                    label: 'description'.translate(this.props.lang),
                                                    multilang: true,
                                                    lang: this.props.lang,
                                                    // validate: [required('description is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'name',
                                                    label: 'Name'.translate(this.props.lang),
                                                    multilang: true,
                                                    lang: this.props.lang,
                                                    validate: [required('Name is required!'.translate(this.props.lang))]
                                                },
                                                {
                                                    type: 'text',
                                                    name: 'alias',
                                                    label: 'Alias'.translate(this.props.lang),
                                                    multilang: true,
                                                    lang: this.props.lang,
                                                    validate: [required('Alias is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [

                                                {
                                                    type: 'checkbox',
                                                    name: 'section1',
                                                    afterText: 'Section 1'.translate(this.props.lang),
                                                },

                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [

                                                {
                                                    type: 'checkbox',
                                                    name: 'section2',
                                                    afterText: 'Section 2'.translate(this.props.lang),
                                                },

                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [

                                                {
                                                    type: 'checkbox',
                                                    name: 'section3',
                                                    afterText: 'Section 3'.translate(this.props.lang),
                                                },

                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [

                                                {
                                                    type: 'checkbox',
                                                    name: 'section4',
                                                    afterText: 'Section 4'.translate(this.props.lang),
                                                },

                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {

                                                    type: 'checkbox',
                                                    name: 'section5',
                                                    afterText: 'Section 5'.translate(this.props.lang),
                                                }

                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {

                                                    type: 'checkbox',
                                                    name: 'section6',
                                                    afterText: 'Section 6'.translate(this.props.lang),
                                                }

                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {

                                                    type: 'checkbox',
                                                    name: 'section7',
                                                    afterText: 'Section 7'.translate(this.props.lang),
                                                }

                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {

                                                    type: 'checkbox',
                                                    name: 'section8',
                                                    afterText: 'Section 8'.translate(this.props.lang),
                                                }

                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {

                                                    type: 'checkbox',
                                                    name: 'section9',
                                                    afterText: 'Section 9'.translate(this.props.lang),
                                                }

                                            ]
                                        },
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 4,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {

                                                    type: 'checkbox',
                                                    name: 'section10',
                                                    afterText: 'Section 10'.translate(this.props.lang),
                                                }

                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                    }
                </Container>
            </div>
        );
    }
}

export default Page(OurSolutionPage);